import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/app.reducer';
import { Store } from '@ngrx/store';
import {
  Product,
  ProductGroup,
  ProductList,
  Products,
  VoucherProducts
} from '@products//models/product-selection.model';
import { PackageType } from '@products/models/package.model';
import { MappedProduct } from '@products/models/product-selection.model';
import {
  ArticleResponse,
  GetProducts as GetProductsPayload,
  GetProductsResponse,
  PackageResponse,
  ProductGroupResponse,
  ProductResponse,
  ProductType,
  TariffResponse,
  TariffTypeResponse,
  WorkshopProductListResponse,
  WorkshopsByDayResponse
} from '@products/models/products.model';
import { Tariff, TariffClassification } from '@products/models/tariff.model';
import { Workshop, WorkshopsByDay } from '@products/models/workshop.model';
import { environment } from '@src/environments/environment';
import { HelperService } from '@store/helpers/helper.service';
import { GetPreferredProducts, GetProducts, GetWorkshopProducts } from '@store/products/product.actions';
import cloneDeep from 'lodash.clonedeep';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadProductService {
  private isSelfRegistration: boolean = false;
  private isWidget: boolean = false;
  private productsList: { [key: string]: VoucherProducts | ProductGroup } = {};
  private packageNumber: number = null;
  private packageIndex: number = 0;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private store: Store<State>
  ) {}

  loadProducts(eventId: number, isWidget: boolean = false): void {
    const { tt, pt } = this.route.snapshot.queryParams;
    const getProductsAction = tt ? GetPreferredProducts : GetProducts;
    const getProductsPayload: GetProductsPayload = {
      eventId,
      preferredTicketGroupNr: +tt || null,
      preferredTicketPersonNr: +pt || null
    };

    this.resetState();

    this.isWidget = isWidget;

    this.store.dispatch(new getProductsAction(getProductsPayload));
  }

  loadWorkshopProducts(eventId: number): void {
    this.store.dispatch(new GetWorkshopProducts(eventId));
  }

  getProducts$(
    eventId: number,
    preferredTicketGroupNr?: number,
    preferredTicketPersonNr?: number
  ): Observable<GetProductsResponse> {
    let params = new HttpParams();
    params = params.append('sr', `${this.isSelfRegistration}`);
    params = params.append('preferredTicketGroupNr', `${preferredTicketGroupNr}`);
    params = params.append('preferredTicketPersonNr', `${preferredTicketPersonNr}`);

    return this.http.get<GetProductsResponse>(
      `${environment.protocol}${environment.webApiUrl}/tickets/${eventId}/grouped-by-section`,
      {
        params
      }
    );
  }

  getWorkshopProducts$(eventId: number) {
    return this.http.get<WorkshopProductListResponse>(
      `${environment.protocol}${environment.webApiUrl}/workshop/workshop-selection/${eventId}`
    );
  }

  getSynchronizedBarcodes$(eventId: number): Observable<void> {
    return this.http.get<void>(`${environment.protocol}${environment.webApiUrl}/tickets/${eventId}/sync-barcodes`);
  }

  resetState(): void {
    this.isSelfRegistration = this.helperService.isSelfregistration();
    this.isWidget = false;
    this.productsList = {};
    this.packageNumber = null;
    this.packageIndex = 0;
  }

  getAndResetLastAddedInitialPackageIndex() {
    const latestAddedInitialPackageIndex = this.packageIndex - 1;

    this.packageIndex = 0;

    return latestAddedInitialPackageIndex;
  }

  mapGetProductResponse(productsResponse: GetProductsResponse): ProductList {
    if (!productsResponse.length) {
      return {};
    }

    this.productsList.vouchers = [] as VoucherProducts;

    productsResponse.forEach((productResponse, index) => {
      const productGroup = this.mapProductGroupResponse(productResponse);

      if (productGroup.products.length) {
        this.productsList[index] = productGroup;
      }
    });

    if (!this.productsList.vouchers.length) {
      delete this.productsList.vouchers;
    }

    return this.productsList;
  }

  mapProductGroupResponse(productGroupResponse: ProductGroupResponse): ProductGroup {
    const { groupId, groupName, groupDescription, items, expanded } = productGroupResponse;

    const productGroup: ProductGroup = {
      groupId,
      groupName,
      groupDescription,
      products: this.mapProductsResponse(items),
      expanded
    };

    return productGroup;
  }

  mapProductsResponse(productsResponse: ProductResponse[]): Products {
    const products: Products = [];

    productsResponse.forEach(productResponse => {
      const mappedProducts = this.mapProductTypeResponse(productResponse);

      mappedProducts.forEach(mappedProduct => {
        mappedProduct.isVoucher && !this.isWidget
          ? (this.productsList.vouchers as VoucherProducts).push(mappedProduct.product)
          : products.push(mappedProduct.product);
      });
    });

    return products;
  }

  mapProductTypeResponse(productResponse: ProductResponse): { isVoucher: boolean; product: Product }[] {
    if (productResponse.itemType === ProductType.Article) {
      return this.mapArticleResponse(productResponse.article);
    } else if (productResponse.itemType === ProductType.Package) {
      return this.mapPackageResponse(productResponse.package);
    } else if (productResponse.itemType === ProductType.Tariff) {
      return this.mapTariffTypeResponse(productResponse.ticketType);
    }
  }

  mapArticleResponse(articleResponse: ArticleResponse): { isVoucher: boolean; product: Product }[] {
    const mappedData = {
      isVoucher: false,
      product: {
        articleType: {
          articles: []
        },
        productType: ProductType.Article
      }
    };

    return !!mappedData.product.articleType.articles.length ? [mappedData] : [];
  }

  mapPackageResponse(packageResponse: PackageResponse): { isVoucher: boolean; product: Product }[] {
    const {
      packageNumber,
      tariff,
      variablePrice,
      packageTariffSheetNumber,
      packageTariffSheetValidNumber,
      info,
      items
    } = packageResponse;

    this.packageNumber = packageNumber;

    const mappedPackage = this.mapPackageItemsResponse(items);
    if (!mappedPackage) {
      return [];
    }

    const packageType: PackageType = {
      packageNumber,
      name: packageResponse.info,
      tariff,
      variablePrice,
      packageTariffSheetNumber,
      packageTariffSheetValidNumber,
      info,
      isDisabled: false,
      isVisible: true,
      numberOfAddedPackages: 0,
      packages: [
        {
          packageIndex: this.packageIndex++,
          trackByAddedPackage: 0,
          products: mappedPackage.products
        }
      ]
    };

    const mappedData: { isVoucher: boolean; product: Product } = {
      isVoucher: mappedPackage.isVoucher,
      product: {
        productType: ProductType.Package,
        packageType
      }
    };

    return [mappedData];
  }

  mapPackageItemsResponse(packageItemsResponse: ProductResponse[]): { isVoucher: boolean; products: Product[] } {
    const mappedData: { isVoucher: boolean; products: Product[] } = {
      isVoucher: false,
      products: []
    };

    packageItemsResponse.forEach(packageItemResponse => {
      const productTypes = this.mapProductTypeResponse(packageItemResponse);

      productTypes.forEach(productType => {
        mappedData.isVoucher = productType.isVoucher;
        mappedData.products.push(productType.product);
      });
    });

    return !!mappedData.products.length ? mappedData : null;
  }

  mapTariffTypeResponse(tariffTypeResponse: TariffTypeResponse): MappedProduct[] {
    const { ticketTypeId, ticketTypeName, preferredTicketType } = tariffTypeResponse;

    const mappedData: MappedProduct = {
      isVoucher: false,
      product: {
        productType: ProductType.Tariff,
        tariffType: {
          ticketTypeId,
          ticketTypeName,
          preferredTicketType,
          isVisible: true,
          tariffs: []
        }
      }
    };

    tariffTypeResponse.tickets.forEach(ticketResponse => {
      const isVoucherTariff = ticketResponse.isVoucher;
      const shouldAddSelfRegistrationTariff =
        this.isSelfRegistration && ticketResponse.classification == TariffClassification.SelfRegistration;
      const shouldAddNotSelfRegistrationTariff =
        !this.isSelfRegistration && ticketResponse.classification != TariffClassification.SelfRegistration;

      if (isVoucherTariff || shouldAddSelfRegistrationTariff || shouldAddNotSelfRegistrationTariff) {
        mappedData.isVoucher = mappedData.isVoucher || isVoucherTariff;
        mappedData.product.tariffType.tariffs.push(
          this.mapTariffResponse({ ...ticketResponse, ticketTypeName }, ticketTypeId)
        );
      }
    });

    if (!mappedData.product.tariffType.tariffs.length) {
      return [];
    }

    if (mappedData.isVoucher) {
      mappedData.product.tariffType.isVisible = this.isWidget;

      const areOnlyVoucherTariffsInTariffType = mappedData.product.tariffType.tariffs.every(tariff => tariff.isVoucher);

      if (!areOnlyVoucherTariffsInTariffType) {
        const tariffTypeWithVoucherTariffs: MappedProduct = cloneDeep(mappedData);
        const tariffTypeWithoutVoucherTariffs: MappedProduct = cloneDeep(mappedData);

        tariffTypeWithVoucherTariffs.product.tariffType.tariffs = tariffTypeWithVoucherTariffs.product.tariffType.tariffs.filter(
          tariff => tariff.isVoucher
        );

        tariffTypeWithoutVoucherTariffs.isVoucher = false;
        tariffTypeWithoutVoucherTariffs.product.tariffType.isVisible = true;
        tariffTypeWithoutVoucherTariffs.product.tariffType.tariffs = tariffTypeWithoutVoucherTariffs.product.tariffType.tariffs.filter(
          tariff => !tariff.isVoucher
        );

        return [tariffTypeWithVoucherTariffs, tariffTypeWithoutVoucherTariffs];
      }
    }

    return [mappedData];
  }

  mapTariffResponse(tariffResponse: TariffResponse & { ticketTypeName: string }, ticketTypeId: number): Tariff {
    const {
      ticketName,
      info,
      infoExpanded,
      price,
      personTypeId,
      ticketPersonId,
      ticketTypeName,
      tariffNr,
      durationInDays,
      availableTickets,
      ticketLimit,
      requiresLegitimation,
      personalizationType,
      classification,
      isVoucher,
      isVisible,
      allowedWorkshops,
      allowedWorkshopsFull,
      workshopsByDay,
      hasDaySellLimit,
      validFrom,
      validTill,
      tageOffset,
      packageSettings
    } = tariffResponse;

    return {
      id: ticketPersonId,
      name: ticketName,
      info,
      infoExpanded,
      price,
      ticketTypeName,
      ticketPersonTypeId: personTypeId,
      durationInDays,
      ticketPersonId,
      ticketTypeId,
      tariffNumber: tariffNr,
      availableTickets,
      ticketLimit,
      requiresLegitimation,
      personalizationType,
      classification,
      isVisible: this.isWidget || isVisible,
      isVoucher,
      allowedWorkshops,
      allowedWorkshopsFull,
      workshopsByDay: this.mapWorkshopsByDayResponseToWorkshopsByDay(workshopsByDay),
      hasDaySellLimit,
      validFrom,
      validTill,
      daysOffset: tageOffset,
      shouldCalendarBeDisplayed: hasDaySellLimit && tageOffset === 31,
      packageSettings,
      packageNumber: packageSettings ? this.packageNumber : null,
      packageIndex: packageSettings ? this.packageIndex : null
    };
  }

  mapWorkshopsByDayResponseToWorkshopsByDay(workshopsByDayResponse: WorkshopsByDayResponse[]) {
    const workshopsByDay: WorkshopsByDay[] = [];

    workshopsByDayResponse.forEach(workshopByDayResponse => {
      const workshops: Workshop[] = [];

      workshopByDayResponse.workshops.forEach(workshopResponse => {
        const workshop: Workshop = { ...workshopResponse };
        workshops.push(workshop);
      });

      workshopsByDay.push({ ...workshopByDayResponse, expanded: false });
    });

    return workshopsByDay;
  }
}
