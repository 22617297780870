import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, getIsWidget, getLanguage, getSelectedExhibition, getSelectedExhibitionId } from '@app/app.reducer';
import { Store, select } from '@ngrx/store';
import { LoadProductService } from '@products/services/load-product.service';
import { environment } from '@src/environments/environment';
import { GetExhibitionSettings, GetLocalizedImages } from '@store/customization/customization.actions';
import { CustomizationService } from '@store/customization/customization.service';
import { GetDepartments, GetOccupationalGroups, GetProfessions, GetTitles } from '@store/exhibition/exhibition.actions';
import { ExhibitionModel } from '@store/exhibition/exhibition.interface';
import { HelperService } from '@store/helpers/helper.service';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExhibitionService {
  exhibitionId$: Observable<number>;
  exhibitionLanguage$: Observable<string>;

  constructor(
    private http: HttpClient,
    private helpers: HelperService,
    private store: Store<State>,
    private customizationService: CustomizationService,
    private loadProductsService: LoadProductService
  ) {
    this.exhibitionId$ = this.store.pipe(select(getSelectedExhibitionId));
    this.exhibitionLanguage$ = this.store.pipe(select(getLanguage));

    /* we skip initial value or value loaded from localstorage,
       so we load exhibition related data only when we realy change exhibition.
       In the reducer no Exhibition ID is set unless it differ from previous one (either from url or localstorage)
    */
    combineLatest([this.exhibitionId$, this.store.pipe(select(getIsWidget))])
      .pipe(
        skip(1),
        filter(([exhibitionId]) => !!exhibitionId || exhibitionId === 0),
        debounceTime(50),
        tap(([exhibitionId, isWidget]) => {
          this.store.dispatch(new GetExhibitionSettings(exhibitionId));
          this.loadProductsService.loadProducts(exhibitionId, isWidget);
          this.loadProductsService.loadWorkshopProducts(exhibitionId);
          this.customizationService.triggerOnExhibitionChange(exhibitionId);
        }),
        withLatestFrom(this.store.pipe(select(getSelectedExhibition))),
        switchMap(([[exhibitionId], selectedExhibition]) => {
          if (!!selectedExhibition && !selectedExhibition.isOver) {
            return this.loadProductsService.getSynchronizedBarcodes$(exhibitionId);
          }

          return EMPTY;
        })
      )
      .subscribe();

    this.exhibitionId$.pipe(skip(1)).subscribe(exhibitionId => {
      const exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;

      this.store.dispatch(new GetTitles(exhibitionOrOperatorId));
      this.store.dispatch(new GetProfessions(exhibitionOrOperatorId));
      this.store.dispatch(new GetDepartments(exhibitionOrOperatorId));
      this.store.dispatch(new GetOccupationalGroups(exhibitionOrOperatorId));
    });

    combineLatest([this.exhibitionId$, this.exhibitionLanguage$])
      .pipe(filter(([exhibitionId]) => !!exhibitionId))
      .subscribe(([exhibitionId, exhibitionLanguage]) => {
        const exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;

        // effects are no more triggered in this loop so call it in next one
        setTimeout(() => {
          this.store.dispatch(
            new GetLocalizedImages({
              eventId: Number(exhibitionOrOperatorId),
              countryCode: exhibitionLanguage
            })
          );
        });
      });
  }

  getUpcomingExhibitionList(langCode: string) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/active/${langCode}`);
  }

  getAllExhibitionsList(langCode: string) {
    return this.http.get<ExhibitionModel[]>(`${environment.protocol}${environment.webApiUrl}/event/all/${langCode}`);
  }

  getDoubleClickScripts(eventId: number) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/double-click-scripts`);
  }

  getGenericScripts(eventId: number) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/scripts`);
  }

  getExhibitionHistoryList() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/user/events-history`);
  }

  getTitles(eventId: number) {
    const selfReg = `?sr=${this.helpers.isSelfregistration()}`;

    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/titles${selfReg}`);
  }

  getProfessions(eventId: number) {
    const selfReg = `?sr=${this.helpers.isSelfregistration()}`;

    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/positions${selfReg}`);
  }

  getDepartments(eventId: number) {
    const selfReg = `?sr=${this.helpers.isSelfregistration()}`;

    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/${eventId}/departments${selfReg}`);
  }

  getOccupationalGroups(eventId: number) {
    const selfReg = `?sr=${this.helpers.isSelfregistration()}`;

    return this.http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/occupational-groups${selfReg}`
    );
  }

  getAllProfessions() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/positions`);
  }

  getAllDepartments() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/departments`);
  }

  getAllOccupationalGroups() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/occupational-groups`);
  }

  getAllTitles() {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event/titles`);
  }

  getEventSeries(seriesId: number, language: string) {
    return this.http.get(`${environment.protocol}${environment.webApiUrl}/event-series/${seriesId}/${language}`);
  }
}
